<template>
  <div class="main accountType_member">
    <div class="container">
      <h1>
        申請帳號<a href="javascript:void(0);" @click="$router.push('/login')"
          >登入</a
        >
      </h1>
      <div class="formBox">
        <p class="desc">
          本頁面僅供會員申請，送出申請後須通過學會理監事會議及繳交會員費用才正式成為學會會員
        </p>
        <a class="m_right_btn" href="/upload/files/TASSM_member_right_0516V2.pdf">會員權益</a>
        <div class="formGroup">
          <div class="formRow">
            <h3>會員類別 *</h3>
            <label for="member_type01">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type01"
                name="member_type"
                :value="1"
              />
              永久會員
            </label>
            <label for="member_type02">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type02"
                name="member_type"
                :value="2"
              />
              個人會員
            </label>
            <label for="member_type03">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type03"
                name="member_type"
                :value="3"
              />
              學生會員
            </label>
            <label for="member_type04">
              <input
                v-model="User.UserType"
                type="radio"
                id="member_type04"
                name="member_type"
                :value="4"
              />
              團體會員
            </label>
          </div>
          <div class="formRow">
            <h3>帳號 *</h3>
            <input
              v-model="User.UserName"
              required
              id="account"
              type="text"
              name=""
              value=""
            /><span>請以身分證字號爲帳號 / 團體請用統一編號</span>
          </div>
          <div class="formRow">
            <h3>Email *</h3>
            <input
              v-model="User.Email"
              required
              id="email"
              type="email"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>姓名 / 團體單位 *</h3>
            <input
              v-model="User.Name"
              required
              id="name"
              type="text"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>密碼 *</h3>
            <input
              v-model="User.Password"
              required
              id="password1"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>確認密碼 *</h3>
            <input
              v-model="User.Password2"
              required
              id="password2"
              type="password"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>連絡電話(手機) *</h3>
            <input
              v-model="User.Mobile"
              required
              id="mobile"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>連絡電話(公司)</h3>
            <input
              v-model="User.CompanyTelephone"
              x-required="User.UserType == 4"
              id="phone_company"
              type="tel"
              name=""
              value=""
            /><span>團體必填，其他選填</span>
          </div>
          <div class="formRow" v-if="false">
            <h3>連絡電話(宅)</h3>
            <input
              v-model="User.Telephone"
              id="phone_home"
              type="tel"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>戶籍地址</h3>
            <input type="text" name="" value="" v-model="User.HomeAddress" />
          </div>
          <div class="formRow">
            <h3>通訊地址</h3>
            <input type="text" name="" value="" v-model="User.ContectAddress" />
          </div>
          <div class="formRow">
            <h3>Line ID</h3>
            <input type="text" name="" value="" v-model="User.LineID" />
          </div>
        </div>
        <div class="formGroup">
          <h4>以下為永久會員、個人會員、學生會員的必填項目</h4>
          <div class="formRow">
            <h3>生日(西元)</h3>
            <input
              v-model="User.Birthday"
              required
              id="birthday"
              type="date"
              name=""
              value=""
            />
          </div>
          <div class="formRow">
            <h3>現職</h3>
            <input
              v-model="User.Job"
              required
              id="job_title"
              type="text"
              name=""
              value=""
            /><span>(公司全名+職稱)EX:臺灣體育運動管理學會專員</span>
          </div>
          <div class="formRow">
            <h3>學歷</h3>
            <input
              v-model="User.School"
              required
              id="education"
              type="text"
              name=""
              value=""
            /><span>(學校全名+系所全名+級別)EX:XX大學運動休閒系碩士</span>
          </div>
          <div class="formRow">
            <h3>運動方面相關經歷</h3>
            <input
              v-model="User.Experience"
              required
              id="experience"
              type="text"
              name=""
              value=""
            /><span
              >(學生會員可填無)EX:籃球選手、OO大學運動管理學系副教授</span
            >
          </div>
        </div>
        <div class="submitBtn" @click="submit">
          <a href="javascript:void(0);">送出申請</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      User: {
        UserType: [],
        UserName: "",
        Email: "",
        Name: "",
        Password: "",
        Password2: "",
        CompanyTelephone: "",
        Telephone: "",
        Mobile: "",
        Birthday: "",
        LineID: "",
        HomeZip: "",
        HomeAddress: "",
        ContectZip: "",
        ContectAddress: "",
      },
    };
  },
  mounted() {
    this.setBase("register");
    this.loadCustomJs();
  },
  methods: {
    async submit() {
      if (!this.validate(".formBox")) {
        return;
      }
      if (this.User.UserType.length == 0) {
        alert("請選擇會員類別");
        return;
      }
      if (this.User.Password != this.User.Password2) {
        alert("兩次密碼不一致，請重新輸入！");
        return;
      }
      try {
        this.User.UserType = [this.User.UserType];
        var result = await this.$api.register(this.User);
        alert("已送出申請，請等待審核通過。");
        this.$router.replace("/");
      } catch (e) {
        if (e.Status == 4) {
          alert("此帳號已註冊過");
        }
      }
    },
  },
};
</script>